import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import type { IRouterLinkProps } from '@hotelplan/components.common.router-link';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { Price, ProductClass, TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { TOpenMethod } from '@hotelplan/libs.router-link-utils';
import { formatTestId } from '@hotelplan/libs.utils';
import { WishlistProduct } from '@hotelplan/platform-graphql-api';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import type { IOfferBreadCrumbsProps } from 'components/domain/offer/OfferBreadCrumbs';
import OfferBreadCrumbs from 'components/domain/offer/OfferBreadCrumbs';
import type { IOfferDatesInformationProps } from 'components/domain/offer/OfferDatesInformation';
import OfferDatesInformation from 'components/domain/offer/OfferDatesInformation';
import ProductCard from 'components/domain/product-card/ProductCard';
import {
  TProductTypeData,
  TTravelersData,
} from 'components/domain/product-card/ProductCard.types';
import SecondaryProductCard from 'components/domain/product-card/SecondaryProductCard';
import ThirdProductCard from 'components/domain/product-card/ThirdProductCard';
import { SRLProductCardVariants } from 'components/domain/srl/SRL.types';
import { PriceFragmentFragment } from 'graphql/price/Price.generated';
import { GroupOfProductFeatureItemsFragment } from 'graphql/productFeature/GroupOfProductFeatureItems.generated';
import { ProductItemFragment } from 'graphql/srl/SRLFragments.generated';

const OfferCloseButton = styled(BsButtonWithIcon)(({ theme: { shadows } }) => ({
  borderRadius: '50%',
  boxShadow: shadows.cardShadow,
  position: 'absolute',
  zIndex: 2,
  left: '13px',
  top: '10px',
  padding: '6px',
  ':after': {
    display: 'block',
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    padding: '25px',
  },
}));

const OfferProductCard = styled(ProductCard)(({ theme: { media }, theme }) => ({
  '.productFeatures .icon': {
    ...theme.icons.md,
    [media.tablet]: {
      ...theme.icons.sm,
    },
  },
}));

const OfferSecondaryProductCard = styled(SecondaryProductCard)(
  ({ theme: { media }, theme }) => ({
    '.productFeatures .icon': {
      ...theme.icons.md,
      [media.tablet]: {
        ...theme.icons.sm,
      },
    },
  })
);

const OfferTypesAndMealType = styled.div(({ theme: { FONT_SIZE } }) => ({
  ...FONT_SIZE.MEDIUM,
}));

const FromTo = styled.span(({ theme: { FONT_SIZE } }) => ({
  ...FONT_SIZE.SMALL,
}));

const OfferDatesInformationWrapper = styled.span<{ isTopPadding: boolean }>(
  ({ theme: { space }, isTopPadding }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: isTopPadding && space[3],
    'white-space': `pre-wrap`,
    '.icon': {
      marginRight: space[2],
      flexShrink: 0,
    },
  })
);

const SecondaryOfferTypesAndMealType = styled.div(
  ({ theme: { FONT_SIZE, space } }) => ({
    ...FONT_SIZE.MEDIUM,
    display: 'flex',
    alignItems: 'center',
    paddingTop: space[1],
    '.icon': {
      marginRight: space[2],
      flexShrink: 0,
    },
  })
);

export interface IOfferItemProps
  extends IOfferDatesInformationProps,
    IOfferBreadCrumbsProps {
  className?: string;
  productTravelType?: TProductTypeData[];
  link: IRouterLinkProps;
  openMethod?: TOpenMethod;
  taRating?: number | null;
  taReviews?: number | null;
  price?: Price;
  totalPrice?: Price;
  disrupter?: string | null | undefined;
  productFeatures?: GroupOfProductFeatureItemsFragment[] | null;
  testId?: string;
  fromTo?: string | null;
  distanceInfo?: string;
  closeButton?: React.ReactNode | null;
  mealType?: string;
  productClass?: ProductClass;
  onClose?(): void;
  onClick?(): void;
  transferPrice?: PriceFragmentFragment | null;
  transferIncluded?: boolean;
  product: ProductItemFragment | WishlistProduct;
  productCardVariant?: SRLProductCardVariants;
  travelers?: TTravelersData;
  travelType?: TravelType;
  isThirdProductCardVariant?: boolean;
}

const OfferItem: React.FC<IOfferItemProps> = ({
  product,
  price,
  link,
  disrupter,
  duration,
  productFeatures,
  taRating,
  taReviews,
  productTravelType,
  departureDate,
  returnDate,
  locations,
  fromTo,
  distanceInfo,
  className,
  openMethod,
  mealType,
  productClass,
  onClose,
  transferPrice,
  transferIncluded,
  productCardVariant,
  travelers,
  totalPrice,
  travelType,
  isThirdProductCardVariant,
  onClick,
}) => {
  const [t] = useTranslation(['results', 'common']);
  const { mobile } = useDeviceType();
  const { name: title, hpRating, image } = product;

  const isSecondaryProductCard =
    productCardVariant === SRLProductCardVariants.NEW_WITH_PERSON_PRICE ||
    productCardVariant === SRLProductCardVariants.NEW;

  const ProductCardVariant = isThirdProductCardVariant
    ? ThirdProductCard
    : isSecondaryProductCard
    ? OfferSecondaryProductCard
    : OfferProductCard;

  const isRoundtripOrCruise = [
    ProductClass.Cruise,
    ProductClass.Roundtrip,
  ].includes(productClass);

  const handleClose = useMemo(
    () =>
      onClose
        ? (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }
        : undefined,
    [onClose]
  );

  const ta = {
    rating: taRating,
    reviews: taReviews,
  };

  return (
    <ProductCardVariant
      className={className}
      productFeatures={productFeatures}
      title={title}
      disrupter={disrupter}
      link={link}
      openMethod={openMethod}
      image={image}
      price={price}
      rating={(hpRating || 0) / 10}
      ta={ta}
      subTitle={<OfferBreadCrumbs locations={locations} />}
      productTravelType={productTravelType}
      testId={`offer-${formatTestId(title)}`}
      distanceInfo={distanceInfo}
      productClass={productClass}
      closeButton={
        handleClose && mobile ? (
          <OfferCloseButton
            variant={E_BUTTON_TYPE.ICON_BUTTON}
            icon={{ name: 'close', size: 'xs' }}
            onClick={handleClose}
            className="offer-close-btn"
          />
        ) : null
      }
      transferPrice={transferPrice}
      transferIncluded={transferIncluded}
      travelers={travelers}
      totalPrice={totalPrice}
      productCardVariant={productCardVariant}
      travelType={travelType}
      onClick={onClick}
    >
      {isSecondaryProductCard ? (
        <>
          {departureDate && returnDate && duration ? (
            <OfferDatesInformationWrapper isTopPadding={isRoundtripOrCruise}>
              <Icon name="calendar" />
              <OfferDatesInformation
                departureDate={departureDate}
                returnDate={returnDate}
                duration={duration}
                travelersCount={
                  !isRoundtripOrCruise ? (
                    <span data-id="offer-travelers">
                      {' | '}
                      {t('common:adult.count', { count: travelers.adults })}
                      {travelers.children > 0 &&
                        `, ${t('common:child.count', {
                          count: travelers.children,
                        })}`}
                    </span>
                  ) : null
                }
              />
            </OfferDatesInformationWrapper>
          ) : null}
          {fromTo && <FromTo>{fromTo}</FromTo>}

          {!isRoundtripOrCruise ? (
            <SecondaryOfferTypesAndMealType>
              {mealType && (
                <>
                  <Icon name="boardtype" />
                  <span>{mealType}</span>
                </>
              )}
            </SecondaryOfferTypesAndMealType>
          ) : null}
        </>
      ) : (
        <>
          {departureDate && returnDate && duration ? (
            <OfferDatesInformation
              departureDate={departureDate}
              returnDate={returnDate}
              duration={duration}
            />
          ) : null}
          {fromTo && <FromTo>{fromTo}</FromTo>}
          {!isRoundtripOrCruise && (
            <OfferTypesAndMealType>
              {productTravelType?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {t(item.title)}
                    {productTravelType.length - 1 !== i && <span> + </span>}
                  </React.Fragment>
                );
              })}
              {mealType && <span>, {mealType}</span>}
            </OfferTypesAndMealType>
          )}
        </>
      )}
    </ProductCardVariant>
  );
};

export default OfferItem;
