import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { IProductCardProps } from 'components/domain/product-card/ProductCard.types';

const ProductCardImageLink = styled(Link)(
  sx2CssThemeFn({
    display: 'block',
    height: '100%',
    '&:hover': {
      color: 'defaultText',
    },
  })
);

const ProductCardWrapper: React.FC<IProductCardProps> = ({
  className,
  link,
  children,
  onClick,
  openMethod,
}) => {
  const imageLink = (
    <ProductCardImageLink
      className={className}
      onClick={onClick}
      target={openMethod}
    >
      {children}
    </ProductCardImageLink>
  );

  return link ? (
    <RouterLink {...link} passHref>
      {imageLink}
    </RouterLink>
  ) : (
    imageLink
  );
};

export default ProductCardWrapper;
