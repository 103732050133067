import { useTranslation } from 'next-i18next';
import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { TransferInfo } from '@hotelplan/components.common.transfer';
import { ProductClass } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { formatPrice } from '@hotelplan/libs.utils';
import Disrupter from 'components/domain/disrupter/Disrupter';
import { TOP_HOTEL_OFFER_ID } from 'components/domain/product-card/productCard.constants';
import { IProductCardProps } from 'components/domain/product-card/ProductCard.types';
import ProductCardWrapper from 'components/domain/product-card/ProductCardWrapper';
import {
  DistanceInfo,
  ProductCardButton,
  ProductCardContent,
  ProductCardContentWrap,
  ProductCardFooter,
  ProductCardHeader,
  ProductCardImage,
  ProductCardImageWrap,
  ProductCardRating,
  ProductCardRows,
  ProductCardSubTitle,
  ProductCardTitle,
  ProductCardWrap,
  ProductFeaturesSummary,
  ProductTravelType,
  ProductTravelTypeTitle,
  TopOffer,
  TravelTypeWrap,
  TripAdvisorInfoWrapper,
} from './ProductCard.styles';

const ProductCard: React.FC<IProductCardProps> = ({
  productTravelType,
  image,
  rating,
  ta,
  title,
  subTitle,
  closeButton,
  children,
  productFeatures,
  price,
  link,
  openMethod,
  onClick,
  testId,
  disrupter,
  distanceInfo,
  productClass,
  pointOfInterests,
  className,
  transferPrice,
  transferIncluded,
  header,
  footer,
}) => {
  const [t] = useTranslation(['common', 'srl']);
  const { mobile } = useDeviceType();

  const topOffer = productFeatures?.filter(
    item => item.id === TOP_HOTEL_OFFER_ID
  );

  const showDistanceInfo =
    !!distanceInfo &&
    (productClass === ProductClass.Hotel ||
      productClass === ProductClass.Apartment);
  const showTransferInfo = !!transferPrice || transferIncluded;

  return (
    <ProductCardWrapper link={link} onClick={onClick} openMethod={openMethod}>
      <ProductCardWrap className={className} data-id={testId}>
        {disrupter && (
          <Disrupter dangerouslySetInnerHTML={{ __html: disrupter }} />
        )}
        <TravelTypeWrap>
          {productTravelType && (
            <ProductTravelType>
              {productTravelType.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <ProductTravelTypeTitle>
                      <Icon name={item.icon} />
                      {t(item.title)}
                    </ProductTravelTypeTitle>
                    {productTravelType.length - 1 !== i && (
                      <div className="delimiter"> + </div>
                    )}
                  </React.Fragment>
                );
              })}
            </ProductTravelType>
          )}
          {topOffer?.length ? (
            <TopOffer>
              {topOffer.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <span>
                      <Icon name={mapFeatureNameToIcon(item.id)} size="sm" />
                      {t(item.name)}
                    </span>
                  </React.Fragment>
                );
              })}
            </TopOffer>
          ) : null}
        </TravelTypeWrap>

        <ProductCardRows>
          {header && <ProductCardHeader>{header}</ProductCardHeader>}

          <ProductCardContent>
            {image && (
              <ProductCardImageWrap>
                <ProductCardImage {...image} />
                {closeButton}
              </ProductCardImageWrap>
            )}

            <ProductCardContentWrap>
              <div className="cardContentText">
                {rating > 0 && (
                  <div className="rating-wrap">
                    <ProductCardRating
                      maxRating={5}
                      rating={rating}
                      ratingContent={<Icon name="hotelplanbewertung" />}
                      halfWayRatingContent={
                        <Icon name="hotelplanbewertung-outlined" />
                      }
                    />
                  </div>
                )}
                {title && <ProductCardTitle>{title}</ProductCardTitle>}
                {subTitle && (
                  <ProductCardSubTitle>{subTitle}</ProductCardSubTitle>
                )}
                {children}
                {showDistanceInfo ? (
                  <DistanceInfo>
                    {`${t('distance.info')}: ${distanceInfo} km`}
                  </DistanceInfo>
                ) : null}
                {showTransferInfo && (
                  <DistanceInfo>
                    <TransferInfo
                      price={transferPrice}
                      transferIncluded={transferIncluded}
                    />
                  </DistanceInfo>
                )}
                {productFeatures?.length ? (
                  <ProductFeaturesSummary
                    groups={productFeatures}
                    successIcon={`checkmark-black`}
                  />
                ) : null}
                {ta?.rating && (
                  <TripAdvisorInfoWrapper
                    rating={ta.rating || 0}
                    reviewCount={ta.reviews || 0}
                    label={t('srl:offers.tripAdvisor')}
                    className="card-trip-advisor"
                  />
                )}
              </div>
            </ProductCardContentWrap>
          </ProductCardContent>

          <ProductCardFooter>
            {footer ? (
              footer
            ) : (
              <>
                {pointOfInterests && !mobile && (
                  <div className="-poi">
                    <Icon name="location" size="lg" />
                    {pointOfInterests}
                  </div>
                )}
                {price && (
                  <p className="-price">
                    <span className="-prefix">
                      {t('common:perPerson.from')}
                    </span>
                    <span data-id="product-price" className="product-price">
                      {formatPrice(price)}
                    </span>
                  </p>
                )}
                <ProductCardButton disabled={!link && !onClick}>
                  <Icon name="chevron-left" size="sm" />
                </ProductCardButton>{' '}
              </>
            )}
          </ProductCardFooter>
        </ProductCardRows>
      </ProductCardWrap>
    </ProductCardWrapper>
  );
};

export default ProductCard;
