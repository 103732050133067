import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { TransferInfo } from '@hotelplan/components.common.transfer';
import { ProductClass } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Disrupter from 'components/domain/disrupter/Disrupter';
import { TOP_HOTEL_OFFER_ID } from 'components/domain/product-card/productCard.constants';
import { IProductCardProps } from 'components/domain/product-card/ProductCard.types';
import ProductCardWrapper from 'components/domain/product-card/ProductCardWrapper';
import ThirdProductCardFooter from 'components/domain/product-card/ThirdProductCardFooter';
import {
  DistanceInfo,
  ProductCardContentWrap,
  ProductCardHeader,
  ProductCardImage,
  ProductCardImageWrap,
  ProductCardRating,
  ProductCardRows,
  ProductCardSubTitle,
  ProductCardTitle,
  ProductFeaturesSummary,
  ProductTravelType,
  ProductTravelTypeTitle,
  TopOffer,
  TripAdvisorInfoWrapper,
} from './ProductCard.styles';

const ThirdProductCardContent = styled.div(
  ({ theme: { colors, media, radii, space } }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    backgroundColor: colors.background,
    border: 'none',
    '.cardImageWrap': {
      borderRadius: radii.default,
    },
    [media.tablet]: {
      padding: space[3],
      flexDirection: 'row',
    },
  })
);

const ThirdProductCardWrap = styled.div(
  sx2CssThemeFn(({ theme: { colors, media, FONT_SIZE, radii } }) => ({
    border: '1px solid',
    borderColor: colors.lightGreyFirst,
    borderRadius: radii.default,
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '100%',
    [media.tablet]: {
      flexDirection: 'row',
    },
    '.disrupter': {
      ...FONT_SIZE.LARGE_BOLD,
      lineHeight: [1.5, 1],
    },
    '.disrupter ~ .travelType': {
      top: '41px',
      [media.tablet]: {
        top: '50px',
      },
    },
    '.card-row': {
      overflow: 'visible!important',
      '.featureGroupWrapper': {
        '&:hover': {
          '.tooltip': {
            zIndex: '10',
          },
        },
      },
      [media.mobile]: {
        '.featureGroupWrapper:first-child': {
          '&:hover': {
            '.tooltip': {
              zIndex: '10',
              left: '-6px!important',
            },
          },
        },
      },
    },
  }))
);

const ThirdTravelTypeWrap = styled.div(
  ({ theme: { media, space, radii } }) => ({
    position: 'absolute',
    right: 'auto',
    left: 'auto',
    top: 'auto',
    margin: space[2],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '.topOffer': {
      borderRadius: radii.default,
      position: 'relative',
      left: space[2],
      padding: `${space[1]} 10px`,
    },
    [media.tablet]: {
      margin: space[4],
      right: 'auto',
      '.topOffer': {
        position: 'relative',
        right: 'auto',
        left: space[2],
      },
    },
  })
);

const ThirdProductCardContentWrap = styled(ProductCardContentWrap)(
  ({ theme: { space, media } }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    [media.tablet]: {
      flexBasis: '470px',
    },
    '.rating-wrap': {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.cardContentText': {
      display: 'flex',
      flexDirection: 'column',
      padding: space[3],
      paddingBottom: 0,
      [media.tablet]: {
        padding: `0 0 0 ${space[3]}`,
      },
    },
  })
);

const ThirdProductCardRating = styled(ProductCardRating)(
  ({ theme: { media } }) => ({
    [media.tablet]: {
      marginBottom: '12px',
    },
  })
);

const ThirdProductTravelType = styled(ProductTravelType)(
  sx2CssThemeFn(({ theme: { radii, space } }) => ({
    borderRadius: radii.default,
    padding: `${space[1]} 10px!important`,
    '.icon': {
      marginRight: '3px',
    },
    '.delimiter': {
      px: '3px',
    },
  }))
);

const ThirdProductCard: React.FC<IProductCardProps> = ({
  productTravelType,
  image,
  rating,
  ta,
  title,
  subTitle,
  closeButton,
  children,
  productFeatures,
  price,
  link,
  openMethod,
  onClick,
  testId,
  disrupter,
  distanceInfo,
  productClass,
  className,
  transferPrice,
  transferIncluded,
  header,
  isSingle = false,
}) => {
  const [t] = useTranslation(['common', 'srl']);
  const { mobile } = useDeviceType();

  const topOffer = productFeatures?.filter(
    item => item.id === TOP_HOTEL_OFFER_ID
  );

  const showDistanceInfo =
    !!distanceInfo &&
    (productClass === ProductClass.Hotel ||
      productClass === ProductClass.Apartment);
  const showTransferInfo = !!transferPrice || transferIncluded;

  return (
    <ProductCardWrapper link={link} onClick={onClick} openMethod={openMethod}>
      <ThirdProductCardWrap className={className} data-id={testId}>
        {disrupter && (
          <Disrupter dangerouslySetInnerHTML={{ __html: disrupter }} />
        )}
        <ThirdTravelTypeWrap className="travelType">
          {productTravelType && (
            <ThirdProductTravelType>
              {productTravelType.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <ProductTravelTypeTitle>
                      <Icon name={item.icon} size="md" />
                      {t(item.title)}
                    </ProductTravelTypeTitle>
                    {productTravelType.length - 1 !== i && (
                      <div className="delimiter"> + </div>
                    )}
                  </React.Fragment>
                );
              })}
            </ThirdProductTravelType>
          )}
          {topOffer?.length ? (
            <TopOffer>
              {topOffer.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <span>
                      <Icon name={mapFeatureNameToIcon(item.id)} size="md" />
                      {t(item.name)}
                    </span>
                  </React.Fragment>
                );
              })}
            </TopOffer>
          ) : null}
        </ThirdTravelTypeWrap>

        <ProductCardRows>
          {header && <ProductCardHeader>{header}</ProductCardHeader>}

          <ThirdProductCardContent className="cardContent card-row">
            {image && (
              <ProductCardImageWrap>
                <ProductCardImage {...image} />
                {closeButton}
              </ProductCardImageWrap>
            )}

            <ThirdProductCardContentWrap className="cardContentWrap">
              <div className="cardContentText">
                {rating > 0 && (
                  <div className="rating-wrap">
                    <ThirdProductCardRating
                      maxRating={5}
                      rating={rating}
                      ratingContent={<Icon name="hotelplanbewertung" />}
                      halfWayRatingContent={
                        <Icon name="hotelplanbewertung-outlined" />
                      }
                    />
                  </div>
                )}
                {title && <ProductCardTitle>{title}</ProductCardTitle>}
                {subTitle && (
                  <ProductCardSubTitle>{subTitle}</ProductCardSubTitle>
                )}
                {children}
                {showDistanceInfo ? (
                  <DistanceInfo>
                    {`${t('distance.info')}: ${distanceInfo} km`}
                  </DistanceInfo>
                ) : null}
                {showTransferInfo && (
                  <DistanceInfo>
                    <TransferInfo
                      price={transferPrice}
                      transferIncluded={transferIncluded}
                    />
                  </DistanceInfo>
                )}
                {productFeatures?.length ? (
                  <ProductFeaturesSummary
                    groups={productFeatures}
                    successIcon={`checkmark-black`}
                  />
                ) : null}
                {ta?.rating && (
                  <TripAdvisorInfoWrapper
                    rating={ta.rating || 0}
                    reviewCount={ta.reviews || 0}
                    label={t(
                      `srl:offers.${
                        mobile ? 'tripAdvisorMobile' : 'tripAdvisor'
                      }`
                    )}
                    className="card-trip-advisor"
                  />
                )}
                {isSingle ? <ThirdProductCardFooter price={price} /> : null}
              </div>
            </ThirdProductCardContentWrap>
          </ThirdProductCardContent>

          {!isSingle ? <ThirdProductCardFooter price={price} /> : null}
        </ProductCardRows>
      </ThirdProductCardWrap>
    </ProductCardWrapper>
  );
};

export default ThirdProductCard;
