import styled from 'styled-components';

const Disrupter = styled.div.attrs(() => ({
  className: `disrupter`,
  'data-id': `disrupter`,
}))(({ theme: { space, fontSizes, colors, radii, media } }) => ({
  background: colors.accentColor,
  color: colors.black,
  borderRadius: radii.roundedTop,
  padding: space[3],
  fontWeight: 'bold',
  lineHeight: 1.5,
  width: '100%',
  fontSize: fontSizes[2],
  marginBottom: -1,
  '& > *': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [media.mobile]: {
    paddingTop: space[2],
    paddingBottom: space[2],
  },
  [media.tablet]: {
    lineHeight: 1.1,
    border: 'none',
    fontSize: '20px',
  },
}));

export default Disrupter;
