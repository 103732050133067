import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type RemoveFromActiveWishlistMutationVariables = Types.Exact<{
  offerId: Types.Scalars['String'];
}>;

export type RemoveFromActiveWishlistMutation = {
  __typename?: 'Mutation';
  removeFromActiveWishlist: {
    __typename?: 'WishlistMutationResponse';
    status: Types.Status;
    message?: string | null;
    activeChanged: boolean;
  };
};

export const RemoveFromActiveWishlistDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveFromActiveWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offerId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFromActiveWishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeChanged' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type RemoveFromActiveWishlistMutationFn = Apollo.MutationFunction<
  RemoveFromActiveWishlistMutation,
  RemoveFromActiveWishlistMutationVariables
>;

/**
 * __useRemoveFromActiveWishlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromActiveWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromActiveWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromActiveWishlistMutation, { data, loading, error }] = useRemoveFromActiveWishlistMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useRemoveFromActiveWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveFromActiveWishlistMutation,
    RemoveFromActiveWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveFromActiveWishlistMutation,
    RemoveFromActiveWishlistMutationVariables
  >(RemoveFromActiveWishlistDocument, options);
}
export type RemoveFromActiveWishlistMutationHookResult = ReturnType<
  typeof useRemoveFromActiveWishlistMutation
>;
export type RemoveFromActiveWishlistMutationResult = Apollo.MutationResult<RemoveFromActiveWishlistMutation>;
export type RemoveFromActiveWishlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromActiveWishlistMutation,
  RemoveFromActiveWishlistMutationVariables
>;
