import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export interface IOfferDatesInformationProps {
  departureDate?: string;
  returnDate?: string;
  duration?: string;
  travelersCount?: JSX.Element;
}

const OfferDatesInformation = styled.p.attrs(
  ({
    departureDate,
    returnDate,
    duration,
    travelersCount,
  }: IOfferDatesInformationProps) => ({
    'data-id': `offer-datesInformation`,
    children: (
      <>
        <span data-id="offer-departureDate">{departureDate}</span> –{' '}
        <span data-id="offer-returnDate">{returnDate}</span>,{' '}
        <span data-id="offer-duration">{duration}</span>
        {travelersCount ? travelersCount : null}
      </>
    ),
  })
)<IOfferDatesInformationProps>(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.MEDIUM,
  })
);

export default OfferDatesInformation;
