import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import {
  RouterLink,
  IRouterLinkProps,
} from '@hotelplan/components.common.router-link';

export interface IOfferBreadCrumbsProps {
  locations: { label: string; link: IRouterLinkProps | null }[];
  className?: string;
}

const OfferBreadCrumbs = styled.div.attrs<IOfferBreadCrumbsProps>(
  ({ locations, className }) => ({
    children: (
      <div className={className}>
        {locations.map(({ link, label }, i) => {
          return (
            <React.Fragment key={label}>
              {link ? (
                <RouterLink {...link} passHref>
                  <Link>{label}</Link>
                </RouterLink>
              ) : (
                <span>{label}</span>
              )}

              {locations.length - 1 !== i ? ', ' : ''}
            </React.Fragment>
          );
        })}
      </div>
    ),
  })
)<IOfferBreadCrumbsProps>(() => ({
  marginBottom: 0,
}));

export default OfferBreadCrumbs;
