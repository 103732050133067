import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Image } from '@hotelplan/components.common.image';
import { FeaturesSummary } from '@hotelplan/components.common.feature';
import { Rating } from '@hotelplan/components.common.rating';
import { TripAdvisorInfo } from '@hotelplan/components.common.tripadvisor-rating';
import { card } from '@hotelplan/components.common.mixins';

export const ProductCardContentWrap = styled.div.attrs({
  className: 'cardContentWrap',
})(({ theme: { media, space } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  [media.tablet]: {
    flexBasis: '470px',
  },
  '.cardContentText': {
    display: 'flex',
    flexDirection: 'column',
    padding: space[3],
    [media.tablet]: {
      paddingLeft: space[5],
      paddingRight: space[5],
    },
  },
  '.rating-wrap': {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const ProductCardContent = styled.div.attrs({
  className: 'cardContent card-row',
})(({ theme: { colors, media } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',
  border: '1px solid',
  borderColor: colors.borderGreyFifth,
  backgroundColor: colors.background,
  [media.tablet]: {
    flexDirection: 'row',
  },
}));

export const ProductCardWrap = styled.div(
  sx2CssThemeFn(card),
  ({ theme: { media, FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: 'flex',
      position: 'relative',
      flexWrap: 'wrap',
      flexDirection: 'column',
      border: 'none',
      height: '100%',
      [media.tablet]: {
        flexDirection: 'row',
      },
      '.disrupter': {
        ...FONT_SIZE.LARGE_BOLD,
        lineHeight: [1.5, 1],
      },
      '.disrupter ~ .travelType': {
        top: '41px',
        [media.tablet]: {
          top: '50px',
        },
      },
    })
);

export const ProductCardRows = styled.div.attrs({
  className: 'card-rows',
})(({ theme: { radii } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',
  '> .card-row': {
    overflow: 'hidden',
  },
  '> .card-row:first-child': {
    borderRadius: radii.roundedTop,
  },
  '> .card-row:last-child': {
    borderRadius: radii.roundedBottom,
  },
  '.card-header + .cardContent': {
    borderTop: 'none',
  },
}));

export const ProductCardImageWrap = styled.div.attrs({
  className: 'cardImageWrap',
})(
  sx2CssThemeFn({
    minHeight: [null, '200px'],
    position: 'relative',
    flexBasis: [null, '298px'],
    height: ['147px', '100%'],
    flexShrink: [null, '0'],
    borderRadius: '3px 3px 0 0',
    overflow: 'hidden',
    a: {
      height: '100%',
    },
  })
);

export const TravelTypeWrap = styled.div.attrs({
  className: 'travelType',
})(({ theme: { media, space } }) => ({
  position: 'absolute',
  right: '10px',
  left: 'auto',
  top: '1px',
  '.topOffer': {
    right: '95%',
    left: 'auto',
  },
  [media.tablet]: {
    right: 'auto',
    left: space[3],
    '.topOffer': {
      right: 'auto',
      left: '95%',
    },
  },
}));

export const ProductTravelType = styled.span(
  ({ theme: { colors, space, FONT_SIZE } }) => ({
    ...FONT_SIZE.SMALL,
    padding: space[2],
    position: 'relative',
    top: '0px',
    borderRadius: '0 0 5px 5px',
    backgroundColor: colors.lightGreySecond,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    '.delimiter': {
      padding: `0 ${space[1]}`,
    },
  })
);

export const ProductTravelTypeTitle = styled.span(({ theme: { space } }) => ({
  display: 'flex',
  alignItems: 'center',
  '.icon': {
    verticalAlign: 'sub',
    marginRight: space[1],
  },
}));

export const TopOffer = styled.span.attrs({
  className: 'topOffer',
})(({ theme: { FONT_SIZE, colors } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.SMALL,
    padding: 2,
    paddingRight: '14px',
    paddingLeft: [2, '14px'],
    position: 'absolute',
    borderRadius: '0 0 5px 5px',
    backgroundColor: colors.mainRed,
    color: 'white',
    zIndex: 1,
    top: 0,
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
    '.icon': {
      verticalAlign: 'text-bottom',
      marginRight: 1,
    },
  })
);

export const ProductCardImage = styled(Image)({
  display: 'block',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

export const ProductCardTitle = styled.h4.attrs({
  className: 'cardTitle',
  'data-id': `product-title`,
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    marginBottom: [1, 0],
  })
);

export const ProductCardSubTitle = styled.span.attrs({
  className: 'cardSubTitle',
})(({ theme: { colors, FONT_SIZE } }) =>
  sx2CssThemeFn({
    color: colors.darkGray,
    ...FONT_SIZE.MEDIUM,
    marginBottom: 1,
  })
);

export const ProductFeaturesSummary = styled(FeaturesSummary)(
  ({ theme: { space, icons, FONT_SIZE } }) => ({
    paddingTop: space[1],
    '.featureGroupWrapper': {
      marginRight: space[3],
      marginBottom: space[1],
      lineHeight: '1',
    },
    '&&& .feature-summary-item-icon': icons.md,
    '.feature-summary-item-name': FONT_SIZE.MEDIUM,
  })
);

export const DistanceInfo = styled.span(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.MEDIUM,
    marginBottom: 1,
  })
);

export const ProductCardHeader = styled.div.attrs({
  className: 'card-header card-row',
  'data-id': `product-header`,
})({ width: '100%' });

export const ProductCardFooter = styled.div.attrs({
  className: 'card-footer card-row',
  'data-id': `product-footer`,
})(({ theme: { colors, space, FONT_SIZE } }) => ({
  display: 'flex',
  border: '1px solid',
  borderTop: 'none',
  borderColor: colors.lightGreyFirst,
  alignItems: 'center',
  marginTop: 'auto',
  width: '100%',
  justifyContent: 'flex-end',
  '.-poi': {
    display: 'flex',
    color: colors.bgDarkBlueSecond,
    '.icon': {
      marginLeft: space[2],
      marginRight: space[1],
    },
  },
  '.product-price': {
    ...FONT_SIZE.XS,
  },
  '.-price': {
    ...FONT_SIZE.MEDIUM,
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: space[2],
  },
  '.-price > .-prefix': {
    ...FONT_SIZE.MEDIUM,
    fontWeight: 'normal',
    marginRight: space[1],
  },
}));

export const ProductCardButton = styled.button.attrs({
  'data-id': `product-link`,
  className: 'card-footer-arrow-btn',
})(({ theme }) => theme.buttons.productCardBtn);

export const ProductCardRating = styled(Rating)(
  ({ theme: { colors, media, space } }) => ({
    marginBottom: space[2],
    color: colors.primary,
    '.rating': {
      marginRight: '3px',
    },
    '.remainRating': {
      marginRight: '3px',
      color: colors.lightGreyFirst,
    },
    [media.tablet]: {
      marginBottom: space[1],
    },
  })
);

export const TripAdvisorInfoWrapper = styled(TripAdvisorInfo)(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      pt: 1,
      img: {
        height: '20px',
        width: '30px',
        mr: 2,
      },
      '.icon': {
        width: '13px',
        height: '13px',
      },
      '.tripAdvisorInfoText': {
        ...FONT_SIZE.SMALLER,
        color: 'darkGray',
        mt: [0, '2px'],
      },
    })
);
