import {
  ProductClass,
  ProductType,
  TravelType,
} from '@hotelplan/graphql.types';
import { TProductTypeData } from 'components/domain/product-card/ProductCard.types';
import { productClassDictionary } from './TravelType.constants';

export const getTravelType = (
  productClass: ProductClass,
  travelType: TravelType
): TProductTypeData[] => {
  return productClass
    ? getProductIcons((travelType as unknown) as ProductType, productClass)
    : travelType === TravelType.Flight
    ? [
        {
          icon: 'flug',
          title: 'common:flight',
        },
      ]
    : undefined;
};

export const getProductIcons = (
  productType: ProductType,
  productClass: ProductClass
): TProductTypeData[] => {
  const productClassIcon = productClassDictionary[productClass];
  if (productType === ProductType.Hotel) {
    return [productClassIcon];
  }
  return [
    {
      icon: 'flug',
      title: 'common:flight',
    },
    productClassIcon,
  ];
};
