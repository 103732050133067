import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { TravelType } from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  ProductCardContentWrap,
  DistanceInfo,
  ProductCardFooter,
} from 'components/domain/product-card/ProductCard.styles';
import { SRLProductCardVariants } from 'components/domain/srl/SRL.types';
import { PriceFragmentFragment } from 'graphql/price/Price.generated';

const ProductCardFooterWrapper = styled(ProductCardFooter)(
  sx2CssThemeFn({
    flexDirection: ['column', 'row'],
    justifyContent: 'space-between',
    padding: 3,
    border: 'none',
  })
);
const SecondaryProductCardContentWrap = styled(ProductCardContentWrap)(
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      flex: '0 1 auto',
      alignSelf: 'flex-start',
      [media.tablet]: {
        flexBasis: 'auto',
      },
      '.cardContentText': {
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        [media.tablet]: {
          paddingLeft: 3,
          paddingRight: 3,
        },
      },
    })
);

const OfferButton = styled(BsButton)(
  sx2CssThemeFn({
    width: ['100%', 'auto'],
    px: '24px',
  })
);

const PriceWrapper = styled.div<{ isRoundtripOrCruise: boolean }>(
  ({ isRoundtripOrCruise }) =>
    sx2CssThemeFn({
      variant: 'link.priceLink',
      display: 'flex',
      letterSpacing: [null, '1.5'],
      p: [null, null],
      py: [null, 0],
      px: [0, 0],
      pt: [isRoundtripOrCruise ? 0 : 3, null],
      ml: [0, 2],
      alignItems: ['center', isRoundtripOrCruise ? 'center' : 'flex-end'],
      justifyContent: 'flex-start',
      width: ['100%', `fit-content`],
      whiteSpace: 'nowrap',
      alignSelf: [null, 'flex-end'],
      flexDirection: ['column', isRoundtripOrCruise ? 'row' : 'column'],
      backgroundColor: 'white',
    })
);

const PricePerPerson = styled.span.attrs({
  'data-id': `offer-price-per-person`,
})<{ isColumnPrice: boolean }>(({ isColumnPrice, theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    pr: [0, isColumnPrice ? 0 : 1],
    '.per-person': {
      ...FONT_SIZE.MEDIUM,
    },
  })
);

const TotalPrice = styled.span.attrs({
  'data-id': `offer-total-price`,
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
  })
);

const PriceInner = styled.div<{
  isRoundtripOrCruise: boolean;
  isColumnPrice: boolean;
}>(({ isRoundtripOrCruise, isColumnPrice }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: [null, isColumnPrice && 'column'],
    justifyContent: 'flex-end',
    mr: [0, isRoundtripOrCruise ? 3 : 0],
    pb: [2, isRoundtripOrCruise ? 0 : 2],
    color: 'black',
  })
);

export const TransferWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
    pt: 1,
    '.icon': {
      marginRight: 2,
      flexShrink: 0,
    },
    '.TransferInfoWrapper': {
      display: 'flex',
      whiteSpace: 'pre-wrap',
    },
  })
);

interface ISecondaryProductCardFooterProps extends React.PropsWithChildren<{}> {
  distanceInfo?: string;
  transferPrice?: PriceFragmentFragment | null;
  transferIncluded?: boolean;
  showDistanceInfo: boolean;
  showTransferInfo: boolean;
  isRoundtripOrCruise: boolean;
  price?: PriceFragmentFragment | null;
  totalPrice?: PriceFragmentFragment | null;
  productCardVariant?: SRLProductCardVariants;
  travelType: TravelType;
}

const SecondaryProductCardFooter: React.FC<ISecondaryProductCardFooterProps> = ({
  children,
  distanceInfo,
  showDistanceInfo,
  showTransferInfo,
  transferPrice,
  transferIncluded,
  price,
  totalPrice,
  productCardVariant,
  isRoundtripOrCruise,
  travelType,
}) => {
  const [t] = useTranslation(['common', 'srl', 'orl']);
  const isColumnPrice =
    travelType === TravelType.Package && !isRoundtripOrCruise;

  return (
    <ProductCardFooterWrapper>
      <SecondaryProductCardContentWrap>
        {children}
        {showDistanceInfo || showTransferInfo ? (
          <TransferWrapper>
            <Icon name="transfer" />
            <p>
              {showDistanceInfo ? (
                <DistanceInfo>
                  {`${distanceInfo} km ${t('distance.secondary.info')}`}
                </DistanceInfo>
              ) : null}
              {showTransferInfo && (
                <DistanceInfo>
                  {showTransferInfo ? ' | ' : ''}
                  <span className="transfer">
                    {transferIncluded
                      ? t('orl:offer.transferIncluded')
                      : t('orl:offer.transfer')}
                  </span>
                  {!transferIncluded ? (
                    <span className="price">
                      {' '}
                      {`${t('common:priceFrom')} ${formatPrice(
                        transferPrice
                      )} ${t('common:transfer.perPerson')}`}
                    </span>
                  ) : null}
                </DistanceInfo>
              )}
            </p>
          </TransferWrapper>
        ) : null}
      </SecondaryProductCardContentWrap>
      <PriceWrapper isRoundtripOrCruise={isRoundtripOrCruise}>
        <PriceInner
          isRoundtripOrCruise={isRoundtripOrCruise}
          isColumnPrice={isColumnPrice}
        >
          <PricePerPerson isColumnPrice={isColumnPrice}>
            <span className="per-person">
              {t('common:perPerson.secondary.from')}
            </span>{' '}
            {formatPrice(price)}
          </PricePerPerson>
          {productCardVariant === SRLProductCardVariants.NEW ? (
            <TotalPrice>
              <span>{t('common:priceFrom')}</span> {formatPrice(totalPrice)}
            </TotalPrice>
          ) : null}
        </PriceInner>
        <OfferButton>{t('orl:toOffers')}</OfferButton>
      </PriceWrapper>
    </ProductCardFooterWrapper>
  );
};

export default SecondaryProductCardFooter;
