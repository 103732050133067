import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { ProductClass } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Disrupter from 'components/domain/disrupter/Disrupter';
import { TOP_HOTEL_OFFER_ID } from 'components/domain/product-card/productCard.constants';
import { IProductCardProps } from 'components/domain/product-card/ProductCard.types';
import ProductCardWrapper from 'components/domain/product-card/ProductCardWrapper';
import SecondaryProductCardFooter from 'components/domain/product-card/SecondaryProductCardFooter';
import {
  ProductCardContent,
  ProductCardImageWrap,
  TravelTypeWrap,
  ProductTravelType,
  ProductTravelTypeTitle,
  TopOffer,
  ProductCardImage,
  ProductFeaturesSummary,
  ProductCardWrap,
  ProductCardSubTitle,
  ProductCardTitle,
  ProductCardRating,
  TripAdvisorInfoWrapper,
  ProductCardContentWrap,
} from './ProductCard.styles';

const SecondaryTripAdvisorInfoWrapper = styled(TripAdvisorInfoWrapper)(
  sx2CssThemeFn({
    pt: 2,
    '.tripAdvisorInfoText': {
      fontSize: 0,
    },
  })
);

const SecondaryProductCardContent = styled(ProductCardContent)(
  sx2CssThemeFn(({ theme: { radii, colors, media, space } }) => ({
    border: 'none',
    borderRadius: radii.default,
    '.cardImageWrap': {
      borderRadius: [`${radii.default} ${radii.default} 0 0`, radii.default],
    },
    [media.tablet]: {
      padding: `0 0 ${space[3]} 0`,
      borderBottom: '1px solid',
      borderBottomColor: colors.lightGreyFirst,
      borderRadius: '0',
    },
  }))
);

const SecondaryProductCardContentContainer = styled.div(
  ({ theme: { space, media } }) =>
    sx2CssThemeFn({
      padding: '0',
      width: '100%',
      [media.tablet]: {
        padding: `${space[3]} ${space[3]} 0 ${space[3]}`,
      },
    })
);

const SecondaryProductCardContentWrap = styled(ProductCardContentWrap)(
  sx2CssThemeFn(({ theme: { media, space, colors } }) => ({
    padding: [`${space[3]} ${space[3]} 0 ${space[3]}`, `0 0 0 ${space[3]}`],
    '.cardContentText': {
      padding: `0 0 ${space[3]} 0`,
      borderBottom: '1px solid',
      borderBottomColor: colors.lightGreyFirst,
      [media.tablet]: {
        padding: '0',
        border: 'none',
      },
    },
  }))
);

const SecondaryProductFeaturesSummary = styled(ProductFeaturesSummary)(
  sx2CssThemeFn(({ theme: { icons, FONT_SIZE } }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat( 2, minmax(1ch, max-content))`,
    gridGap: 1,
    maxWidth: '470px',
    paddingTop: '0',
    '.featureGroupWrapper': {
      marginRight: 4,
      marginBottom: 1,
      flexDirection: 'row',
      width: 'fit-content',
      ...FONT_SIZE.SMALL,
    },
    '.featureGroupWrapper:nth-child(even)': {
      marginRight: 0,
    },
    '.feature-summary-item-icon': {
      flexShrink: 0,
    },
    '.icon': { ...icons.md, marginRight: 1 },
  }))
);

const SecondaryProductCardSubTitle = styled(ProductCardSubTitle)(
  sx2CssThemeFn({
    mb: '12px',
    color: 'darkGray',
  })
);

const SecondaryTravelTypeWrap = styled(TravelTypeWrap)(
  sx2CssThemeFn(({ theme: { radii, space } }) => ({
    right: 'auto',
    left: 'auto',
    top: 'auto',
    margin: [2, 4],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '.topOffer': {
      position: 'relative',
      left: 2,
      borderRadius: radii.default,
      padding: `${space[1]} 10px`,
    },
  }))
);
const SecondaryProductTravelType = styled(ProductTravelType)(
  sx2CssThemeFn(({ theme: { radii, space } }) => ({
    borderRadius: radii.default,
    padding: `${space[1]} 10px`,
    '.icon': {
      marginRight: '3px',
    },
    '.delimiter': {
      px: '3px',
    },
  }))
);

const SecondaryTopOffer = styled(TopOffer)(
  sx2CssThemeFn({
    paddingLeft: '14px',
    paddingRight: 2,
  })
);

const SecondaryProductCardRating = styled(ProductCardRating)(
  ({ theme: { media } }) => ({
    [media.tablet]: {
      marginBottom: '12px',
    },
  })
);

const SecondaryProductCard: React.FC<IProductCardProps> = ({
  productTravelType,
  image,
  rating,
  ta,
  title,
  subTitle,
  closeButton,
  children,
  productFeatures,
  price,
  link,
  openMethod,
  onClick,
  testId,
  disrupter,
  distanceInfo,
  productClass,
  className,
  transferPrice,
  transferIncluded,
  productCardVariant,
  totalPrice,
  travelType,
}) => {
  const [t] = useTranslation(['common', 'srl', 'orl']);
  const { mobile } = useDeviceType();

  const topOffer = productFeatures?.filter(
    item => item.id === TOP_HOTEL_OFFER_ID
  );

  const showDistanceInfo =
    !!distanceInfo &&
    (productClass === ProductClass.Hotel ||
      productClass === ProductClass.Apartment);
  const showTransferInfo = !!transferPrice || transferIncluded;
  const isRoundtripOrCruise = [
    ProductClass.Cruise,
    ProductClass.Roundtrip,
  ].includes(productClass);

  return (
    <ProductCardWrapper link={link} onClick={onClick} openMethod={openMethod}>
      {disrupter && (
        <Disrupter dangerouslySetInnerHTML={{ __html: disrupter }} />
      )}
      <ProductCardWrap className={className} data-id={testId}>
        <SecondaryTravelTypeWrap>
          {productTravelType && (
            <SecondaryProductTravelType>
              {productTravelType.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <ProductTravelTypeTitle>
                      <Icon name={item.icon} />
                      {t(item.title)}
                    </ProductTravelTypeTitle>
                    {productTravelType.length - 1 !== i && (
                      <div className="delimiter"> + </div>
                    )}
                  </React.Fragment>
                );
              })}
            </SecondaryProductTravelType>
          )}
          {topOffer?.length ? (
            <SecondaryTopOffer>
              {topOffer.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <span>
                      <Icon name={mapFeatureNameToIcon(item.id)} size="sm" />
                      {t(item.name)}
                    </span>
                  </React.Fragment>
                );
              })}
            </SecondaryTopOffer>
          ) : null}
        </SecondaryTravelTypeWrap>

        <SecondaryProductCardContentContainer>
          <SecondaryProductCardContent>
            {image && (
              <ProductCardImageWrap>
                <ProductCardImage {...image} />
                {closeButton}
              </ProductCardImageWrap>
            )}

            <SecondaryProductCardContentWrap>
              <div className="cardContentText">
                {rating > 0 && (
                  <div className="rating-wrap">
                    <SecondaryProductCardRating
                      maxRating={5}
                      rating={rating}
                      ratingContent={<Icon name="hotelplanbewertung" />}
                      halfWayRatingContent={
                        <Icon name="hotelplanbewertung-outlined" />
                      }
                    />
                  </div>
                )}
                {title && <ProductCardTitle>{title}</ProductCardTitle>}
                {subTitle && (
                  <SecondaryProductCardSubTitle>
                    {subTitle}
                  </SecondaryProductCardSubTitle>
                )}
                {isRoundtripOrCruise && children}
                {productFeatures?.length && !isRoundtripOrCruise ? (
                  <SecondaryProductFeaturesSummary
                    groups={productFeatures.slice(0, 4)}
                    withName
                    successIcon={`checkmark-black`}
                  />
                ) : null}
                {ta?.rating && (
                  <SecondaryTripAdvisorInfoWrapper
                    rating={ta.rating || 0}
                    reviewCount={ta.reviews || 0}
                    label={t(
                      `srl:offers.${
                        mobile ? 'tripAdvisorMobile' : 'tripAdvisor'
                      }`
                    )}
                    className="card-trip-advisor"
                  />
                )}
              </div>
            </SecondaryProductCardContentWrap>
          </SecondaryProductCardContent>
        </SecondaryProductCardContentContainer>

        <SecondaryProductCardFooter
          distanceInfo={distanceInfo}
          showDistanceInfo={showDistanceInfo}
          showTransferInfo={showTransferInfo}
          transferIncluded={transferIncluded}
          transferPrice={transferPrice}
          price={price}
          totalPrice={totalPrice}
          productCardVariant={productCardVariant}
          isRoundtripOrCruise={isRoundtripOrCruise}
          travelType={travelType}
        >
          {!isRoundtripOrCruise && children}
        </SecondaryProductCardFooter>
      </ProductCardWrap>
    </ProductCardWrapper>
  );
};

export default SecondaryProductCard;
