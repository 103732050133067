import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { formatPrice } from '@hotelplan/libs.utils';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { PriceFragmentFragment } from 'graphql/price/Price.generated';

const ThirdCardFooterWrapper = styled.div(
  ({ theme: { colors, space, FONT_SIZE } }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    width: '100%',
    justifyContent: 'flex-end',
    backgroundColor: colors.white,
    padding: space[3],
    paddingTop: 0,
    border: 'none',
    '.-poi': {
      display: 'flex',
      color: colors.bgDarkBlueSecond,
      '.icon': {
        marginLeft: space[2],
        marginRight: space[1],
      },
    },
    '.product-price': {
      ...FONT_SIZE.XS,
    },
    '.-price': {
      ...FONT_SIZE.MEDIUM,
      fontWeight: 'bold',
      textAlign: 'right',
      marginRight: space[2],
    },
    '.-price > .-prefix': {
      ...FONT_SIZE.MEDIUM,
      fontWeight: 'normal',
      marginRight: space[1],
    },
  })
);

const PriceWrapper = styled.div(
  sx2CssThemeFn({
    variant: 'link.priceLink',
    display: 'flex',
    letterSpacing: [null, '1.5'],
    p: 0,
    pt: [3, null],
    alignItems: 'center',
    justifyItems: 'end',
    width: `fit-content`,
    whiteSpace: 'nowrap',
    alignSelf: [null, 'flex-end'],
    backgroundColor: 'white',
  })
);

const PricePerPerson = styled.span.attrs({
  'data-id': `offer-price-per-person`,
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    pr: 3,
    '.per-person': {
      ...FONT_SIZE.MEDIUM,
      fontWeight: '400',
    },
  })
);

const PriceInner = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    mr: 0,
    color: 'black',
  })
);

interface IThirdProductCardFooterProps {
  price?: PriceFragmentFragment | null;
  className?: string;
  buttonVariant?: E_BUTTON_TYPE;
}

const ThirdProductCardFooter: React.FC<IThirdProductCardFooterProps> = ({
  price,
  className,
  buttonVariant,
}) => {
  const [t] = useTranslation(['common', 'srl', 'orl']);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <ThirdCardFooterWrapper
      data-id="product-footer"
      className={`card-footer card-row ${className}`}
    >
      <PriceWrapper>
        <PriceInner>
          <PricePerPerson className="price-per-person">
            <span className="per-person">
              {t('common:perPerson.secondary.from')}
            </span>{' '}
            {formatPrice(price)}
          </PricePerPerson>
        </PriceInner>
        <BsButtonWithIcon
          ref={buttonRef}
          onClick={() => {
            buttonRef?.current.blur();
          }}
          icon={{ name: 'chevron-right', size: 'lg' }}
          variant={
            buttonVariant
              ? buttonVariant
              : E_BUTTON_TYPE.CIRCLE_BUTTON_SECONDARY
          }
        />
      </PriceWrapper>
    </ThirdCardFooterWrapper>
  );
};

export default ThirdProductCardFooter;
